<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh; position: absolute; left: 50%; z-index: 9999;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
      <b-row>
          <b-col cols="12">
              <b-card class="my-card m-0">
                  <b-table 
                  striped
                  small
                  id="myTables"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="assigment"
                  :fields="field"
                  head-row-variant="secondary"
                  @row-clicked="clicked"
                  >
                  <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>
                  <template #cell(performer_first_name)="data">
                    <span>
                      {{ data.item.performer_last_name + ' ' + data.item.performer_first_name }}
                    </span>
                  </template>
                  <template #cell(performer_login)="data">
                    <span @dblclick="getLogin(data)" class="reference-field">
                      {{ data.item.performer_login }}
                    </span>
                  </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                  </b-table>
              </b-card>
          </b-col>
      </b-row>
      <filter-modalVue @sendToParent="sendToParent" :fields="fields"></filter-modalVue>
  </div>
</template>

<script>
import { cellSelect } from '@/utils/TableFunctions/cellSelect'
import { resizeable } from '@/utils/TableFunctions/resizeable.js'
export default {
  data(){
      return{
          order_id: this.$route.query.order_id,
          assigment: [],
          showPreloader: false,
          field: [
          {key: 'checkbox', label: '', thStyle: {width: '30px'}},
          {key: 'order_division_id', label: 'Подразделение', sortable: true, thStyle: {width: '130px'}},
          {key: 'performer_division_id', label: 'Подразделение водителя', sortable: true, thStyle: {width: '130px'}},
          {key: 'order_id', label: 'ID заказа', sortable: true, thStyle: {width: '130px'}},
          {key: 'status_name', label: 'Статус', sortable: true, thStyle: {width: '130px'}},
          {key: 'result', label: 'Результат', sortable: true, thStyle: {width: '130px'}},
          {key: 'result_info', label: 'Результат(название)', sortable: true, thStyle: {width: '160px'}},
          {key: 'performer_first_name', label: 'ФИО водителя', sortable: true, thStyle: {width: '130px'}},
          {key: 'performer_login', label: 'Логин', sortable: true, thStyle: {width: '130px'}},
          {key: 'distance', label: 'Дистанция', sortable: true, thStyle: {width: '130px'}},
          {key: 'rating', label: 'Рейтинг', sortable: true, thStyle: {width: '130px'}},
          {key: 'order_commission', label: 'Коммиссия', sortable: true, thStyle: {width: '130px'}},
          {key: 'driver_balance', label: 'Баланс водителя', sortable: true, thStyle: {width: '130px'}},
          {key: 'request_order_at', label: 'Время запроса', sortable: true, thStyle: {width: '130px'}},
          {key: 'created_at', label: 'Дата создания', sortable: true, thStyle: {width: '130px'}},
          {key: 'lat', label: 'Широта', sortable: true, thStyle: {width: '130px'}},
          {key: 'lng', label: 'Долгота', sortable: true, thStyle: {width: '130px'}},
      ],
      fields: [
          { key: 'division_order', label: 'Подразделение заказов' },
          { key: 'drivers_division', label: 'Подразделение' },
          {key: 'order_id', label: 'ID заказа'},
          {key: 'status_name', label: 'Статус'},
          {key: 'last_name', label: 'ФИО'},
          // {key: 'request_order_at', label: 'Время запроса'},
          {key: 'result', label: 'Результат'},
          {key: 'performer_login', label: 'Результат'},
          {key: 'performer_distance', label: 'Дистанция'},
          {key: 'performer_rating', label: 'Райтинг'},
          {key: 'commission_order', label: 'Комиссия'},
          {key: 'driver_balance', label: 'Баланс водителя'},
          {key: 'lat', label: 'Широта'},
          {key: 'lgt', label: 'Долгота'},
          // {key: 'created_at', label: 'Дата создания'},
      ],
      }
  },
  methods:{
      sendToParent(tableData){
            this.assigment = tableData
            this.$store.commit('pageData/setdataCount', this.assigment.length)
        },
      openFilter(){
        this.assigment = []
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = [];
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.assigment = res.data
                        this.$store.commit('pageData/setdataCount', this.assigment.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        getLogin(data) {
          this.$router.push({name: 'infoDriver', query:{filter: data.item.performer_id,}})
          this.$store.commit('draggableTab/ADD_TAB', ['Водитель ' + data.item.performer_id, `/infoDriver`, data.item.performer_id])
        },
        clicked(item, index, event){
        this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.field.length) {
                return;
            }

            const clickedField = this.field[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.textContent).trim()
            cellSelect()
        },
  },
  mounted(){
      this.$store.commit('pageData/setdataCount', null)
      resizeable()
      this.openFilter()
  },
  computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
              this.assigment = []
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.assigment = res.data
                    this.$store.commit('pageData/setdataCount', this.assigment.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
  padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
.my-card{
  overflow: auto; 
  height: 530px;
  }
}
table{
  outline: none; 
  table-layout: fixed; 
  -webkit-user-select: none;
  overflow: hidden;
  position: relative;
}
tr, td, th{
  padding: 0;
}
td, th{ 
  width: 100px; 
  white-space: nowrap; 
  overflow: hidden;  
  text-align: left; 
  box-sizing: border-box; 
  min-width: 60px;
  }
.my-danger{
  background-color: #f9cfcf;
}  
.my-success{
  background-color: #8fe2b4;
} .my-secondary{
  background-color: #dcdddf;
} .my-warning{
  background-color: #ffcd9d;
}   
</style>